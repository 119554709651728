<template>
  <div class="incubatorBGView">
    <device-msg-head :order="order"
                     device-type="incubatorInfo"
                     :device-mac="incubatorInfo.deviceMac"
                     :device-station-mac="'基站:' + incubatorInfo.baseStation"
    ></device-msg-head>
    <div style="height: 5px"></div>
    <device-msg-cell v-for="(key) in dataList" :key="key" :content-str="key + ':' + dataMsg(key)">
    </device-msg-cell>
    <device-msg-footer :rssi="dataMsg('rssi')" :time-stamp="dataMsg('dataTime')"></device-msg-footer>
    <div style="width: 95%;height: 1px;background-color: #cccccc;margin: 5px 0px 5px 0px"></div>
  </div>
</template>

<script>

import DeviceMsgHead from '@/views/deviceMQ/deviceMsg/deviceMsgHead'
import DeviceMsgFooter from '@/views/deviceMQ/deviceMsg/deviceMsgFooter'
import DeviceMsgCell from '@/views/deviceMQ/deviceMsg/deviceMsgCell'

export default {
  name: 'incubatorCell',
  components: { DeviceMsgCell, DeviceMsgFooter, DeviceMsgHead },
  props: {
    order: {
      type: String,
      default: () => {
        return '#'
      }
    },
    incubatorInfo: {
      type: Object,
      default: () => {
        return {
          'baseStation': '',
          'battery': '',
          'deviceMac': '',
          'epc': '',
          'id': '',
          'pc': '',
          'rssi': 0,
          'rssi2': 0,
          'status': 0,
          'type': 0,
          'work': ''
        }
      }
    }
  },
  computed: {
    dataList() {
      return [
        'baseStation',
        'battery',
        'deviceMac',
        'epc',
        'id',
        'pc',
        'rssi',
        'rssi2',
        'status',
        'type',
        'work'
      ]
    }
  },
  methods: {
    dataMsg: function(key) {
      if (typeof this.incubatorInfo[key] == 'undefined' || this.incubatorInfo[key] == null) {
        return ''
      }
      return this.incubatorInfo[key]
    }
  }
}
</script>

<style lang="scss" scoped>

.incubatorBGView {
  width: 100%;
}

</style>
