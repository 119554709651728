<template>
  <div :id="loadingDivId" class="IncubatorViewViewBGView">
    <ul class="infinite-list-wrapper viewList" v-infinite-scroll="updateMsgList" style="overflow:scroll">
      <li v-for="(item, index) in dataList" class="infinite-list-item viewLi" :infinite-scroll-disabled="disabled">
        <incubator-cell :incubator-info="item" :order="'#' + (index + 1)"></incubator-cell>
      </li>
    </ul>
  </div>
</template>

<script>

import MQmixin from '@/views/deviceMQ/MQmixin/MQmixin'
import IncubatorCell from '@/views/deviceMQ/IncubatorView/incubatorCell'

export default {
  name: 'IncubatorView',
  components: { IncubatorCell },
  mixins: [MQmixin],
  data() {
    return {
      viewType:'温箱',
      loadingDivId: 'incubatorLoading',
      urlBlock: (config) => {
        return this.$api.deviceMQ.getIncubatorInfoList(config)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.IncubatorViewViewBGView {
  width: 100%;
  height: 100%;

  .viewList {
    width: calc(100%);
    //height: calc(100% - 40px);
    height: calc(100% - 40px);
    list-style: none;
    padding: 0;

    .viewLi {
      width: calc(100%);
      margin-bottom: 5px;
    }
  }

}

</style>
